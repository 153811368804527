import React, { FC } from 'react';
import { InView } from 'react-intersection-observer';
import Link from 'next/link';

import { GlobalAnimationStyleObject } from '../../../../sites/animations';
import MediaElement from '../../../basic/MediaElement';
import { CustomMedia } from '../../../types/custom-types';
import {
  ColourTheme,
  ContentCustomPosition,
  FontSize,
  HtmlLink,
  ImageV2,
  Margin,
  Maybe,
  MultipleDefaultContentPositions,
  TextAlignment,
} from '../../../types/middleware-types';
import formatPromoEvent from '../../../utils/DataLayer/formatPromoEvent';
import ModuleContent from '../MediaModuleContent';
import {
  StyledModuleItemLinkWrapper,
  StyledModuleItemMediaWrapper,
  StyledModuleItemWrapper,
} from './ModuleItemStyles';

import 'intersection-observer';

export interface ModuleItemStructureProps {
  id?: string;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  animate?: boolean;
  animationType?: Maybe<string>;
  animationStyle: GlobalAnimationStyleObject;
  callToActionLinkWrapsItem?: boolean;
  parentIsLink?: boolean;
  index: number;
  moduleIndex: number;
  marginDesktop: Margin;
  marginMobile: Margin;
  hideOnMobile?: Maybe<boolean>;
  media: CustomMedia;
  imgPriority?: boolean;
  handlePromoView: (
    isVisible: boolean,
    itemIndex: number,
    itemTitle: string,
    url: string,
    image: string,
    itemId: string,
  ) => void;
  contentProps: {
    title?: Maybe<string>;
    subtitle?: Maybe<string>;
    description?: Maybe<string>;
    itemLogo?: Maybe<ImageV2>;
    callToAction?: Maybe<HtmlLink>;
    textAlignment?: Maybe<TextAlignment>;
    fontSize?: FontSize;
    colourTheme?: ColourTheme;
    contentDefaultPositionDesktop: MultipleDefaultContentPositions;
    contentCustomPositionDesktop: ContentCustomPosition;
    contentDefaultPositionMobile: MultipleDefaultContentPositions;
    contentCustomPositionMobile: ContentCustomPosition;
    numberTitle?: string;
  };
  sizes?: string;
  useReactRoutingLinks: boolean;
}

const ModuleItemStructure: FC<ModuleItemStructureProps> = ({
  contentProps,
  media,
  contentDefaultPositionDesktop,
  contentDefaultPositionMobile,
  callToActionLinkWrapsItem,
  marginMobile,
  marginDesktop,
  hideOnMobile,
  animationType,
  animate,
  animationStyle,
  index,
  moduleIndex,
  handlePromoView,
  id,
  parentIsLink,
  imgPriority,
  sizes,
  useReactRoutingLinks,
}) => {
  const isLink = !parentIsLink && callToActionLinkWrapsItem && contentProps?.callToAction?.url;

  return (
    <>
      {useReactRoutingLinks ? (
        <Link href={isLink ? contentProps?.callToAction?.url as string : ''} passHref legacyBehavior>
          <>{isLink ? (
            <StyledModuleItemLinkWrapper
              {...(contentProps?.callToAction?.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
              $contentDefaultPositionMobile={contentDefaultPositionMobile}
              $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
              $marginDesktop={marginDesktop}
              $marginMobile={marginMobile}
              $hideOnMobile={hideOnMobile}
              $animationType={animationType}
              data-hookid="ModuleItemWrapper"
              data-test="123"
              href={contentProps?.callToAction?.url}
              onClick={() =>
                formatPromoEvent(
                  contentProps?.callToAction?.text,
                  index ? index + 1 : 1,
                  contentProps?.callToAction?.url,
                  media?.defaultImageUrl,
                  'hero banner',
                  'click',
                )}
            >
              <StyledModuleItemMediaWrapper
                $animationType={animationType}
                $animate={animate}
                $animationStyle={animationStyle}
                $moduleIndex={moduleIndex}
              >
                <InView
                  threshold={0.7}
                  onChange={(isVisible: boolean) =>
                    handlePromoView(
                      isVisible,
                      index,
                      contentProps?.title || '',
                      contentProps?.callToAction?.url || '',
                      media?.defaultImageUrl || '',
                      id || '',
                    )}
                >
                  <MediaElement
                    {...media}
                    label={contentProps?.callToAction?.text}
                    imgPriority={imgPriority}
                    imageType={sizes ? 'responsive' : 'intrinsic'}
                    sizes={sizes}
                  />
                </InView>
              </StyledModuleItemMediaWrapper>
              <ModuleContent
                {...contentProps}
                animationType={animationType}
                animate={animate}
                animationStyle={animationStyle}
                callToActionLinkWrapsItem={callToActionLinkWrapsItem}
              />
            </StyledModuleItemLinkWrapper>
          ) :
            (
              <StyledModuleItemWrapper
                {...(contentProps?.callToAction?.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
                $contentDefaultPositionMobile={contentDefaultPositionMobile}
                $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
                $marginDesktop={marginDesktop}
                $marginMobile={marginMobile}
                $hideOnMobile={hideOnMobile}
                $animationType={animationType}
                data-hookid="ModuleItemWrapper"
                onClick={() =>
                  formatPromoEvent(
                    contentProps?.callToAction?.text,
                    index ? index + 1 : 1,
                    contentProps?.callToAction?.url,
                    media?.defaultImageUrl,
                    'hero banner',
                    'click',
                  )}
              >
                <StyledModuleItemMediaWrapper
                  $animationType={animationType}
                  $animate={animate}
                  $animationStyle={animationStyle}
                  $moduleIndex={moduleIndex}
                >
                  <InView
                    threshold={0.7}
                    onChange={(isVisible: boolean) =>
                      handlePromoView(
                        isVisible,
                        index,
                        contentProps?.title || '',
                        contentProps?.callToAction?.url || '',
                        media?.defaultImageUrl || '',
                        id || '',
                      )}
                  >
                    <MediaElement
                      {...media}
                      label={contentProps?.callToAction?.text}
                      imgPriority={imgPriority}
                      imageType={sizes ? 'responsive' : 'intrinsic'}
                      sizes={sizes}
                    />
                  </InView>
                </StyledModuleItemMediaWrapper>
                <ModuleContent
                  {...contentProps}
                  animationType={animationType}
                  animate={animate}
                  animationStyle={animationStyle}
                  callToActionLinkWrapsItem={callToActionLinkWrapsItem}
                />
              </StyledModuleItemWrapper>
            )}
          </>
        </Link>
      ) : (
        <>{isLink ? (
          <StyledModuleItemLinkWrapper
            {...(contentProps?.callToAction?.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
            $contentDefaultPositionMobile={contentDefaultPositionMobile}
            $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
            $marginDesktop={marginDesktop}
            $marginMobile={marginMobile}
            $hideOnMobile={hideOnMobile}
            $animationType={animationType}
            data-hookid="ModuleItemWrapper"
            onClick={() =>
              formatPromoEvent(
                contentProps?.callToAction?.text,
                index ? index + 1 : 1,
                contentProps?.callToAction?.url,
                media?.defaultImageUrl,
                'hero banner',
                'click',
              )}
          >
            <StyledModuleItemMediaWrapper
              $animationType={animationType}
              $animate={animate}
              $animationStyle={animationStyle}
              $moduleIndex={moduleIndex}
            >
              <InView
                threshold={0.7}
                onChange={(isVisible: boolean) =>
                  handlePromoView(
                    isVisible,
                    index,
                    contentProps?.title || '',
                    contentProps?.callToAction?.url || '',
                    media?.defaultImageUrl || '',
                    id || '',
                  )}
              >
                <MediaElement
                  {...media}
                  label={contentProps?.callToAction?.text}
                  imgPriority={imgPriority}
                  imageType={sizes ? 'responsive' : 'intrinsic'}
                  sizes={sizes}
                />
              </InView>
            </StyledModuleItemMediaWrapper>
            <ModuleContent
              {...contentProps}
              animationType={animationType}
              animate={animate}
              animationStyle={animationStyle}
              callToActionLinkWrapsItem={callToActionLinkWrapsItem}
            />
          </StyledModuleItemLinkWrapper>
        ) :
          (
            <StyledModuleItemWrapper
              {...(contentProps?.callToAction?.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
              $contentDefaultPositionMobile={contentDefaultPositionMobile}
              $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
              $marginDesktop={marginDesktop}
              $marginMobile={marginMobile}
              $hideOnMobile={hideOnMobile}
              $animationType={animationType}
              data-hookid="ModuleItemWrapper"
              onClick={() =>
                formatPromoEvent(
                  contentProps?.callToAction?.text,
                  index ? index + 1 : 1,
                  contentProps?.callToAction?.url,
                  media?.defaultImageUrl,
                  'hero banner',
                  'click',
                )}
            >
              <StyledModuleItemMediaWrapper
                $animationType={animationType}
                $animate={animate}
                $animationStyle={animationStyle}
                $moduleIndex={moduleIndex}
              >
                <InView
                  threshold={0.7}
                  onChange={(isVisible: boolean) =>
                    handlePromoView(
                      isVisible,
                      index,
                      contentProps?.title || '',
                      contentProps?.callToAction?.url || '',
                      media?.defaultImageUrl || '',
                      id || '',
                    )}
                >
                  <MediaElement
                    {...media}
                    label={contentProps?.callToAction?.text}
                    imgPriority={imgPriority}
                    imageType={sizes ? 'responsive' : 'intrinsic'}
                    sizes={sizes}
                  />
                </InView>
              </StyledModuleItemMediaWrapper>
              <ModuleContent
                {...contentProps}
                animationType={animationType}
                animate={animate}
                animationStyle={animationStyle}
                callToActionLinkWrapsItem={callToActionLinkWrapsItem}
              />
            </StyledModuleItemWrapper>
          )}
        </>
      )}
    </>
  );
};

export default ModuleItemStructure;
