import styled from 'styled-components';

import { GlobalAnimationStyleObject } from '../../../../sites/animations';
import { StyledNextImageWrapper } from '../../../basic/NextImage/NextImageStyles';
import { FullMediaWrapper } from '../../../styles/contentBlockMedia';
import {
  ContentBlockAnimationMediaBasic,
  Margin,
  Maybe,
  MultipleDefaultContentPositions,
} from '../../../types/middleware-types';
import { StyledMobileCarousel } from '../../Carousel/MobileCarousel/MobileCarouselStyles';
import { StyledSlider } from '../../Slider/SliderStyles';
import {
  StyledModuleItemLinkWrapper,
  StyledModuleItemWrapper,
} from '../MediaModuleItem/ModuleItemStyles';

interface StyledModuleWrapperProps {
  $marginMobile: Margin;
  $marginDesktop: Margin;
  $backgroundColour?: Maybe<string>;
  $contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  $contentDefaultPositionMobile: MultipleDefaultContentPositions;
}

export const StyledModuleWrapper = styled.div<StyledModuleWrapperProps>`
  display: flex;
  width: auto;
  justify-content: flex-start;
  position: relative;
  ${FullMediaWrapper};
`;

interface StyledModuleItemsWrapperProps {
  $animate: boolean;
  $animationType?: Maybe<ContentBlockAnimationMediaBasic>;
  $animationStyle: GlobalAnimationStyleObject;
}

export const StyledModuleItemsWrapper = styled.div<StyledModuleItemsWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
    width: auto;
  }

  ${StyledModuleItemLinkWrapper} {
    text-transform: unset;
  }

  video,
  picture,
  ${StyledNextImageWrapper} {
    source,
    img {
      display: block;
      ${({ $animate, $animationType, $animationStyle }) =>
  $animate && $animationType === 'BACKGROUNDPARALLAX' && $animationStyle.zoomIn};
    }
  }
`;

interface StyledScrollMobileWrapperProps {
  $itemWidth: number;
}

export const StyledScrollMobileWrapper = styled.div<StyledScrollMobileWrapperProps>`
  ${StyledMobileCarousel} {
    white-space: initial;
    display: flex;
  }

  ${StyledModuleItemWrapper} {
    width: ${({ $itemWidth }) => $itemWidth}%;
    flex: 0 0 ${({ $itemWidth }) => $itemWidth}%;
  }

  ${StyledModuleItemLinkWrapper} {
    width: ${({ $itemWidth }) => $itemWidth}%;
    flex: 0 0 ${({ $itemWidth }) => $itemWidth}%;
  }

  ${StyledSlider} {
    display: none;
  }
`;

export const StyledMediaDesktopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface StyledLargeMediaItemDesktopWrapperProps {
  $index: number;
  $activeIndex: Maybe<number>;
  $smallWidth: number;
  $hoveredMediaSpacesNumber: Maybe<number>;
}

export const StyledLargeMediaItemDesktopWrapper = styled.div<StyledLargeMediaItemDesktopWrapperProps>`
  flex: ${({ $activeIndex, $index, $hoveredMediaSpacesNumber }) =>
  $activeIndex === $index && $hoveredMediaSpacesNumber ? $hoveredMediaSpacesNumber : 1};
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: flex 1s cubic-bezier(0.29, 0, 0.2, 1);
`;

interface StyledSmallMediaItemDesktopWrapperProps {
  $index: number;
  $smallWidth: number;
  $hoveredMediaSpacesNumber: Maybe<number>;
  $activeIndex: Maybe<number>;
}

export const StyledSmallMediaItemDesktopWrapper = styled.div<StyledSmallMediaItemDesktopWrapperProps>`
  width: ${({ $hoveredMediaSpacesNumber, $smallWidth }) =>
  $hoveredMediaSpacesNumber ? $hoveredMediaSpacesNumber * $smallWidth : $smallWidth}px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;
