import styled, { css } from 'styled-components';

import { GlobalAnimationStyleObject } from '../../../../sites/animations';
import { StyledNextImageWrapper } from '../../../basic/NextImage/NextImageStyles';
import { FullMediaWrapper } from '../../../styles/contentBlockMedia';
import { Margin, Maybe, MultipleDefaultContentPositions } from '../../../types/middleware-types';

export interface StyledModuleItemWrapperProps {
  $marginDesktop: Margin;
  $marginMobile: Margin;
  $contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  $contentDefaultPositionMobile: MultipleDefaultContentPositions;
  $animationType?: Maybe<string>;
  $hideOnMobile?: Maybe<boolean> | undefined;
}

const moduleItemWrapperStyle = css<StyledModuleItemWrapperProps>`
  box-sizing: border-box;
  position: relative;
  width: auto;
  flex: 1;
  align-self: stretch;
  ${({ $animationType }) =>
  ($animationType === 'MOVEFADE' || $animationType === 'BLOCKMOVEFADE') && 'overflow: hidden;'}
  display: ${({ $hideOnMobile }) => ($hideOnMobile ? 'none' : 'flex')};
  ${FullMediaWrapper};

  @media ${({ theme }) => theme.devices.large} {
    display: flex;
    align-self: flex-start;
    flex: ${({ $hideOnMobile }) => ($hideOnMobile ? '0 0 auto' : '1 1 0')};
    width: ${({ $hideOnMobile }) => ($hideOnMobile ? '66.66%' : 'auto')};
  }
`;
export const StyledModuleItemWrapper = styled.div<StyledModuleItemWrapperProps>`
  ${moduleItemWrapperStyle}
`;
export const StyledModuleItemLinkWrapper = styled.a<StyledModuleItemWrapperProps>`
  ${moduleItemWrapperStyle}
`;

interface StyledModuleItemMediaWrapperProps {
  $animate?: boolean;
  $animationType?: Maybe<string>;
  $animationStyle: GlobalAnimationStyleObject;
  $moduleIndex: number;
}

export const StyledModuleItemMediaWrapper = styled.div<StyledModuleItemMediaWrapperProps>`
  width: 100%;
  overflow-x: hidden;
  ${({ $animationType, theme }) =>
  $animationType === 'MOVEFADE' || $animationType === 'BLOCKMOVEFADE'
    ? `
        transform: translateY(-100%);
        background: ${theme.vars.white};`
    : ''};
  ${({ $animate, $animationType, $animationStyle }) => {
      if ($animate) {
        if ($animationType === 'MOVEFADE' || $animationType === 'BLOCKMOVEFADE') {
          return $animationStyle.scrollDown;
        }
      }
      return null;
    }}

  img {
    width: 100% !important;
    height: unset !important;
  }

  video,
  picture,
  ${StyledNextImageWrapper} {
    source,
    img {
      width: 100%;
      display: block;
      ${({ $animationType }) =>
      $animationType === 'MOVEFADE' || $animationType === 'BLOCKMOVEFADE' ? 'opacity: 0' : ''};
      ${({ $animate, $animationType, $animationStyle, $moduleIndex }) => {
        if ($animate) {
          if (
            ($animationType === 'BACKGROUNDSHRINK' && $moduleIndex === 0) ||
            $animationType === 'BACKGROUNDPARALLAX'
          ) {
            return $animationStyle.zoomIn;
          }
          if ($animationType === 'MOVEFADE' || $animationType === 'BLOCKMOVEFADE') {
            return $animationStyle.fadeIn;
          }
        }
        return null;
      }}
    }
  }
`;
