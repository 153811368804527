import React, { FC } from 'react';
import Link from 'next/link';

import { GlobalAnimationStyleObject } from '../../../../sites/animations';
import MediaElement from '../../../basic/MediaElement';
import { CustomMedia } from '../../../types/custom-types';
import {
  ColourTheme,
  FontSize,
  HtmlLink,
  Maybe,
  TextAlignment,
} from '../../../types/middleware-types';
import formatPromoEvent from '../../../utils/DataLayer/formatPromoEvent';
import { StyledModuleContentLogo, StyledModuleContentWrapper } from './ModuleContentStyles';

export interface ModuleContentStructureProps {
  title?: Maybe<string>;
  subtitle?: Maybe<string>;
  description: string;
  textAlignment: Maybe<TextAlignment>;
  fontSize?: FontSize;
  colourTheme?: ColourTheme;
  callToAction?: Maybe<HtmlLink>;
  animate?: boolean;
  animationType?: Maybe<string>;
  animationStyle: GlobalAnimationStyleObject;
  callToActionLinkWrapsItem?: boolean;
  logo: CustomMedia;
  absoluteDesktop: boolean;
  absoluteMobile: boolean;
  topDesktop?: Maybe<number>;
  leftDesktop?: Maybe<number>;
  topMobile?: Maybe<number>;
  leftMobile?: Maybe<number>;
  topElement: string;
  textTopDesktop: boolean;
  textBottomDesktop: boolean;
  textTopMob: boolean;
  textBottomMob: boolean;
  numberTitle?: string;
  linkAttr: {
    target?: string,
    rel?: string,
  }
  kgRedesign?: boolean,
  useReactRoutingLinks: boolean;
}

const ModuleContentStructure: FC<ModuleContentStructureProps> = ({
  title,
  subtitle,
  description,
  logo,
  callToAction,
  textAlignment,
  colourTheme,
  absoluteDesktop,
  topDesktop,
  leftDesktop,
  absoluteMobile,
  topMobile,
  leftMobile,
  animationType,
  animate,
  animationStyle,
  fontSize,
  callToActionLinkWrapsItem,
  topElement,
  textTopDesktop,
  textBottomDesktop,
  textTopMob,
  textBottomMob,
  numberTitle,
  linkAttr,
  kgRedesign,
  useReactRoutingLinks,
}) => {
  return (
    <StyledModuleContentWrapper
      $absoluteMobile={absoluteMobile}
      $topMobile={topMobile}
      $leftMobile={leftMobile}
      $absoluteDesktop={absoluteDesktop}
      $topDesktop={topDesktop}
      $leftDesktop={leftDesktop}
      $textAlignment={textAlignment}
      $colourTheme={colourTheme}
      $animationType={animationType}
      $animate={animate}
      $animationStyle={animationStyle}
      $fontMediaSize={fontSize}
      $topElement={topElement}
      $textTopDesktop={textTopDesktop}
      $textBottomDesktop={textBottomDesktop}
      $textTopMob={textTopMob}
      $textBottomMob={textBottomMob}
      $data-hookid="moduleContentWrapper"
      $kgRedesign={kgRedesign}
    >
      {logo && (
        <StyledModuleContentLogo
          $absoluteMobile={absoluteMobile}
          $absoluteDesktop={absoluteDesktop}
          $textAlignment={textAlignment}
        >
          <MediaElement {...logo} imageType="intrinsic" />
        </StyledModuleContentLogo>
      )}
      {numberTitle && <h2 className="number-title">{numberTitle}</h2>}
      {subtitle && <p className="custom-size-subtitle">{subtitle}</p>}
      {title && (
        <div className="custom-size-title">
          <h2>{title}</h2>
        </div>
      )}
      {description && (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: description }}
          className="custom-size-media-description"
        />
      )}
      {callToAction?.text && callToAction?.url && (
        <>
          {callToActionLinkWrapsItem ? (
            <div className="custom-size-link">{callToAction.text}</div>
          ) : (
            <>
              {useReactRoutingLinks ? (
                <Link href={callToAction.url} passHref legacyBehavior>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <a
                    className="custom-size-link"
                    {...linkAttr}
                    onClick={() => formatPromoEvent(title, 1, callToAction.url, '', 'cta1', 'click')}
                  >
                    {callToAction.text}
                  </a>
                </Link>
              ) : (
                <a
                  className="custom-size-link"
                  href={callToAction.url}
                  {...linkAttr}
                  onClick={() => formatPromoEvent(title, 1, callToAction.url, '', 'cta1', 'click')}
                >
                  {callToAction.text}
                </a>
              )}
            </>
          )}
        </>
      )}
    </StyledModuleContentWrapper>
  );
};

export default ModuleContentStructure;
