'use client'

import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { selectPageName } from "../../../features/static";
import {
  ContentBlockAnimationMediaBasic,
  ContentBlockMediaBasic,
  ContentBlockMobileLayoutMediaBasic,
  Maybe,
  TextAlignment,
} from '../../../types/middleware-types';
import useAnimationOnScroll from '../../../utils/customHooks/useAnimationOnScroll';
import useMobileWatcher from '../../../utils/customHooks/useMobileWatcher';
import MediaBasicStructure from './MediaBasicStructure';

export interface MediaBasicProps extends ContentBlockMediaBasic {
  index?: number;
  animationMediaBasic?: Maybe<ContentBlockAnimationMediaBasic>;
}

// Module ABCDIJ
const MediaBasic: FC<MediaBasicProps> = ({
  items,
  animationMediaBasic: animationType,
  index: moduleIndex = 0,
  title,
  callToAction,
  textAlignment = TextAlignment.Centre,
  mobileLayout = ContentBlockMobileLayoutMediaBasic.Stack,
  backgroundColour,
  ...otherProps
}) => {
  const { enteredView, animationStyle, forwardedRef } = useAnimationOnScroll(animationType);
  const [smallWidth, setSmallWidth] = useState<Maybe<number>>(null);
  const [hoveredMediaSpacesNumber, setHoveredMediaSpacesNumber] = useState<Maybe<number>>(null);
  const [activeIndex, setActiveIndex] = useState<Maybe<number>>(null);
  const [windowWidth, setWindowWidth] = useState<number>(
    typeof window !== 'undefined' ? window.innerWidth : 300,
  );
  const theme = useTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const pageName = useSelector(selectPageName);

  // Removed useLayoutEffect as didn't play ball with SSR,
  // look into workaround if causing issues.
  useEffect(() => {
    let timer: Maybe<NodeJS.Timeout> = null;
    const computeWindowWidth = () => {
      if (isDesktop) {
        timer = setTimeout(() => {
          setWindowWidth(window.innerWidth);
        });
      }
    };
    if (animationType === 'IMAGEHOVEREXPAND') {
      window.addEventListener('resize', computeWindowWidth);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      if (animationType === 'IMAGEHOVEREXPAND') {
        window.removeEventListener('resize', computeWindowWidth);
      }
    };
  }, []);

  // Compute width of small and large parts if animation type is IMAGEHOVEREXPAND
  useEffect(() => {
    if (animationType === 'IMAGEHOVEREXPAND') {
      if (isDesktop) {
        setSmallWidth(windowWidth / 8);
        setHoveredMediaSpacesNumber(9 - items.length);
        setActiveIndex(0);
      } else {
        setSmallWidth(windowWidth / items.length);
      }
    }
  }, [animationType, items, windowWidth, isDesktop]);

  const setActiveOnHover = (index: number): void => {
    setActiveIndex(index);
  };

  // Needed for rehydration console error.
  const SSRDesktopCheck = isDesktop ?? false;

  const hasNoItems = items.length === 0 || Object.values(items).every((x) => !x.colourTheme);
  const imageWidth = animationType === 'IMAGEHOVEREXPAND' ? (100 * (9 - items.length)) / 8 : 100 / items.length
  const nextImageSizes = items.length
    ? `(max-width: 1024px) 100vw, ${imageWidth}vw`
    : undefined;

  const componentProps = {
    ...otherProps,
    items,
    title,
    textAlignment,
    mobileLayout,
    backgroundColour,
    callToAction,
    smallWidth,
    hoveredMediaSpacesNumber,
    activeIndex,
    setActiveOnHover,
    isDesktop: SSRDesktopCheck,
    pageName,
    forwardedRef,
    animationType,
    animate: enteredView,
    animationStyle,
    moduleIndex,
    nextImageSizes,
  };

  return <>{!hasNoItems && <MediaBasicStructure {...componentProps} />}</>;
};

export default MediaBasic;
