'use client';

import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { GlobalAnimationsStyle, GlobalAnimationStyleObject } from '../../../../sites/animations';
import { selectPageName, selectToggles } from '../../../features/static';
import {
  ColourTheme,
  ContentCustomPosition,
  FontSize,
  HtmlLink,
  ImageV2,
  Margin,
  Maybe,
  Media,
  MultipleDefaultContentPositions,
  ProductMedia,
  TextAlignment,
} from '../../../types/middleware-types';
import { DEFAULT_ON_SCROLL_STYLE } from '../../../utils/customHooks/useAnimationOnScroll';
import formatPromoEvent from '../../../utils/DataLayer/formatPromoEvent';
import parseCMSMediaProp from '../../../utils/formatters/parseCMSMediaProp';
import ModuleItemStructure from './ModuleItemStructure';

export interface ModuleItemProps {
  id?: string;
  title?: Maybe<string>;
  subtitle?: Maybe<string>;
  description?: Maybe<string>;
  textAlignment?: Maybe<TextAlignment>;
  fontSize?: Maybe<FontSize>;
  fontSizeParent?: FontSize;
  colourTheme?: ColourTheme;
  callToAction?: Maybe<HtmlLink>;
  contentDefaultPositionDesktop?: MultipleDefaultContentPositions;
  contentCustomPositionDesktop?: ContentCustomPosition;
  contentDefaultPositionMobile?: MultipleDefaultContentPositions;
  contentCustomPositionMobile?: ContentCustomPosition;
  animate?: boolean;
  animationType?: Maybe<string>;
  animationStyle?: GlobalAnimationsStyle | GlobalAnimationStyleObject;
  callToActionLinkWrapsItem?: boolean;
  itemLogo?: Maybe<ImageV2>;
  parentIsLink?: boolean;
  index?: number;
  moduleIndex?: number;
  marginDesktop?: Margin;
  marginMobile?: Margin;
  hideOnMobile?: Maybe<boolean>;
  media?: Maybe<Media> | ProductMedia;
  numberTitle?: string;
  imgPriority?: boolean;
  sizes?: string;
}

const ModuleItem: FC<ModuleItemProps> = ({
  id,
  title,
  subtitle,
  description,
  itemLogo,
  callToAction,
  callToActionLinkWrapsItem,
  textAlignment = TextAlignment.Centre,
  colourTheme,
  contentCustomPositionDesktop = { topPercent: null, leftPercent: null },
  contentDefaultPositionDesktop = MultipleDefaultContentPositions.Below,
  contentCustomPositionMobile = { topPercent: null, leftPercent: null },
  contentDefaultPositionMobile = MultipleDefaultContentPositions.Below,
  marginDesktop = {},
  marginMobile = {},
  hideOnMobile,
  media,
  animationType,
  animate,
  animationStyle = DEFAULT_ON_SCROLL_STYLE,
  index = 0,
  moduleIndex = 0,
  fontSize,
  fontSizeParent,
  parentIsLink,
  numberTitle,
  imgPriority,
  sizes,
}) => {
  const pageName = useSelector(selectPageName);
  const toggles = useSelector(selectToggles);
  const [seenItems, setSeenItems] = useState<string[]>([]);
  const useReactRoutingLinks = toggles?.engs14345ReactRoutingATagsToLinks;

  // Send DL event
  const handlePromoView = (
    isVisible: boolean,
    itemIndex: number,
    itemTitle: string,
    url: string,
    image: string,
    itemId: string,
  ) => {
    const identifier = itemId ?? itemTitle;
    const eventDescription = itemTitle ?? '';
    const isHomepage = pageName === 'HomePage';
    const position = itemIndex ? itemIndex + 1 : 1;
    if (
      toggles?.engs9083SendDlPromoViewEventsForHomepageBlocks &&
      isVisible &&
      !seenItems.includes(identifier) &&
      isHomepage
    ) {
      formatPromoEvent(eventDescription, position, url ?? '', image, 'hero', 'view');
      setSeenItems([...seenItems, identifier]);
    }
  };

  const animObjStyle: GlobalAnimationStyleObject =
    typeof animationStyle !== 'function' ? animationStyle : {};
  const animStyle: GlobalAnimationStyleObject =
    animationType === 'BLOCKMOVEFADE' && animationStyle && typeof animationStyle === 'function'
      ? animationStyle(index * 200)
      : animObjStyle;

  const componentProps = {
    media: parseCMSMediaProp(media as Media),
    contentProps: {
      title,
      subtitle,
      description,
      itemLogo,
      callToAction,
      colourTheme,
      textAlignment,
      contentCustomPositionDesktop,
      contentDefaultPositionDesktop,
      fontSize: fontSize || fontSizeParent,
      contentCustomPositionMobile,
      contentDefaultPositionMobile,
      numberTitle,
    },
    id,
    contentDefaultPositionDesktop,
    contentDefaultPositionMobile,
    callToActionLinkWrapsItem,
    marginDesktop,
    marginMobile,
    hideOnMobile,
    animationType,
    animate,
    animationStyle: animStyle,
    index,
    moduleIndex,
    handlePromoView,
    parentIsLink,
    imgPriority,
    sizes,
    useReactRoutingLinks
  };
  

  return <ModuleItemStructure {...componentProps} />;
};

export default ModuleItem;
