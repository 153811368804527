import styled from 'styled-components';

import { GlobalAnimationStyleObject } from '../../../../sites/animations';
import { CustomSizeDescription, MediaFontSize } from '../../../styles/fontSize';
import { ColourTheme, FontSize, Maybe, TextAlignment } from '../../../types/middleware-types';

interface StyledModuleContentWrapperProps {
  $textAlignment: Maybe<TextAlignment>;
  $colourTheme?: ColourTheme;
  $fontMediaSize?: FontSize;
  $absoluteDesktop: boolean;
  $absoluteMobile: boolean;
  $topDesktop?: Maybe<number>;
  $leftDesktop?: Maybe<number>;
  $topMobile?: Maybe<number>;
  $leftMobile?: Maybe<number>;
  $animate?: boolean;
  $animationType?: Maybe<string>;
  $animationStyle: GlobalAnimationStyleObject;
  $topElement: string;
  $textTopDesktop: boolean,
  $textBottomDesktop: boolean,
  $textTopMob: boolean,
  $textBottomMob: boolean,
  $kgRedesign?: boolean,
}

export const StyledModuleContentWrapper = styled.article<StyledModuleContentWrapperProps>`
  padding: ${({ $textBottomMob }) =>  $textBottomMob ? '1.5rem' : '0'} 0.8rem ${({ $textTopMob, $textBottomMob }) =>  $textTopMob || $textBottomMob ? '1.5rem' : '0'};
  text-align: ${({ $textAlignment }) =>
  $textAlignment !== 'CENTRE' ? $textAlignment?.toLowerCase() : 'center'};
  color: ${({ theme, $colourTheme }) => {
    if ($colourTheme === 'LIGHT') {
      return theme.blockTextColors.light;
    }
    if ($colourTheme === 'DARK') {
      return theme.blockTextColors.dark;
    }
    return theme.blockTextColors.default;
  }};
  box-sizing: border-box;
  position: ${({ $absoluteMobile }) => ($absoluteMobile ? 'absolute' : 'static')};
  top: ${({ $topMobile }) => ($topMobile === null ? 50 : $topMobile)}%;
  left: ${({ $leftMobile }) => ($leftMobile === null ? 50 : $leftMobile)}%;
  width: ${({ $leftMobile, $topMobile, $absoluteDesktop, $kgRedesign }) => {
    if ($leftMobile === null && $absoluteDesktop) {
      return '80%';
    }
    if ($topMobile !== null && $leftMobile === null) {
      return '95%';
    }    
    if ($leftMobile === null && $absoluteDesktop && $kgRedesign) {
      return '90%';
    }
    return 'auto';
  }};
  transform: ${({ $topMobile, $leftMobile, $absoluteMobile }) => {
    if (!$absoluteMobile || ($topMobile !== null && $leftMobile !== null)) {
      return 'translate(0, 0)';
    }
    if ($topMobile === null && $leftMobile !== null) {
      return 'translate(0, -50%)';
    }
    if ($topMobile !== null && $leftMobile === null) {
      return 'translate(-50%, 0)';
    }
    return 'translate(-50%, -50%)';
  }};

  @media ${({ theme }) => theme.devices.medium} {
    padding-top: ${({ $textBottomDesktop }) =>  $textBottomDesktop ? '1.5rem' : '0'};
    padding-bottom: ${({ $textTopDesktop }) =>  $textTopDesktop ? '1.5rem' : '0'};
    position: ${({ $absoluteDesktop }) => ($absoluteDesktop ? 'absolute' : 'static')};
    top: ${({ $topDesktop }) => ($topDesktop === null ? 50 : $topDesktop)}%;
    left: ${({ $leftDesktop }) => ($leftDesktop === null ? 50 : $leftDesktop)}%;
    width: ${({ $leftDesktop, $absoluteDesktop, $kgRedesign }) =>
    // eslint-disable-next-line no-nested-ternary
    $leftDesktop === null && $absoluteDesktop ? ($kgRedesign ? '90%' : '80%') : 'auto'};
    transform: ${({ $topDesktop, $leftDesktop, $absoluteDesktop }) => {
      if (!$absoluteDesktop || ($topDesktop !== null && $leftDesktop !== null)) {
        return 'translate(0, 0)';
      }
      if ($topDesktop === null && $leftDesktop !== null) {
        return 'translate(0, -50%)';
      }
      if ($topDesktop !== null && $leftDesktop === null) {
        return 'translate(-50%, 0)';
      }
      return 'translate(-50%, -50%)';
    }};
  }

  .number-title {
    margin: 0 0 0.5rem;
    font-size: 4rem;
    line-height: 4rem;

    @media ${({ theme }) => theme.devices.large} {
      margin: 0 0 0.5rem;
      font-size: 8rem;
      line-height: 8rem;
    }
  }

  .custom-size-title {
    margin: ${({ $topElement, theme }) =>
      $topElement !== 'title' ? theme.blockTopMargins.heading : 0}
      0 0;
    overflow: hidden;
  }

  .custom-size-subtitle {
    font-weight: normal !important;
    text-transform: uppercase;
    margin: 0.6rem 0 0.3rem;
  }

  h2 {
    ${({ $animate, $animationType, $animationStyle }) =>
        $animate && $animationType === 'BACKGROUNDPARALLAX' && $animationStyle.moveUp}
  }

  a,
  .custom-size-link,
  .cta {
    margin: ${({ $topElement, theme }) => ($topElement !== 'cta' ? theme.blockTopMargins.cta : 0)} 0 0;
    ${({ theme }) => theme.types.tertiaryLinkStyles};
    color: inherit;
    font-weight: 400;
    display: inline-block;
    ${({ $animate, $animationType, $animationStyle }) =>
          $animate && $animationType === 'BACKGROUNDPARALLAX' && $animationStyle.fadeIn}
  }

  .custom-size-media-description {
    margin: ${({ $topElement, theme }) =>
            $topElement !== 'description' ? theme.blockTopMargins.description : 0}
      0 0;

    h1 {
      line-height: calc(${({ $fontMediaSize, theme }) => ($fontMediaSize === 'SMALL' && theme.cmsPages.h1LineHeightFix) ||
    ($fontMediaSize === 'LARGE' && theme.blockFontSizes.descriptionCtaL) ||
    theme.blockFontSizes.descriptionCtaM} + 0.2rem) !important;
    }

    p {
      margin: 0.3rem 0;
      font-weight: normal !important;
      ${CustomSizeDescription};
    }
  }
  ${MediaFontSize};
`;

interface StyledModuleContentLogoProps {
  $absoluteDesktop: boolean;
  $absoluteMobile: boolean;
  $textAlignment: Maybe<TextAlignment>;
}

export const StyledModuleContentLogo = styled.div<StyledModuleContentLogoProps>`
  display: ${({ $absoluteMobile }) => ($absoluteMobile ? 'flex' : 'none')};
  justify-content: ${({ $textAlignment }) =>
  ($textAlignment === 'CENTRE' && 'center') ||
    ($textAlignment === 'LEFT' && 'flex-start') ||
    'flex-end'};

  @media ${({ theme }) => theme.devices.medium} {
    display: ${({ $absoluteDesktop }) => ($absoluteDesktop ? 'flex' : 'none')};
  }

  picture {
    width: auto;
  }
`;
