import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { GlobalAnimationStyleObject } from '../../../../sites/animations';
import { selectToggles } from '../../../features/static';
import {
  ColourTheme,
  ContentCustomPosition,
  FontSize,
  HtmlLink,
  ImageV2,
  Maybe,
  MultipleDefaultContentPositions,
  TextAlignment,
} from '../../../types/middleware-types';
import useMobileWatcher from '../../../utils/customHooks/useMobileWatcher';
import cleanHtml from '../../../utils/formatters/cleanHtml';
import parseCMSMediaProp from '../../../utils/formatters/parseCMSMediaProp';
import ModuleContentStructure from './ModuleContentStructure';

export interface ModuleContentProps {
  title?: Maybe<string>;
  subtitle?: Maybe<string>;
  description?: Maybe<string>;
  textAlignment?: Maybe<TextAlignment>;
  fontSize?: FontSize;
  colourTheme?: ColourTheme;
  callToAction?: Maybe<HtmlLink>;
  contentDefaultPositionDesktop?: MultipleDefaultContentPositions;
  contentCustomPositionDesktop?: ContentCustomPosition;
  contentDefaultPositionMobile?: MultipleDefaultContentPositions;
  contentCustomPositionMobile?: ContentCustomPosition;
  animate?: boolean;
  animationType?: Maybe<string>;
  animationStyle?: GlobalAnimationStyleObject;
  callToActionLinkWrapsItem?: boolean;
  itemLogo?: Maybe<ImageV2>;
  numberTitle?: string;
}

const ModuleContent: FC<ModuleContentProps> = ({
  title,
  subtitle,
  description,
  itemLogo,
  callToAction,
  textAlignment = TextAlignment.Centre,
  colourTheme,
  contentDefaultPositionDesktop = MultipleDefaultContentPositions.Below,
  contentCustomPositionDesktop: {
    topPercent: topPercentDesktop,
    leftPercent: leftPercentDesktop,
  } = {},
  contentCustomPositionMobile: {
    topPercent: topPercentMobile,
    leftPercent: leftPercentMobile,
  } = {},
  contentDefaultPositionMobile = MultipleDefaultContentPositions.Below,
  animationType,
  animate,
  animationStyle = {},
  fontSize,
  callToActionLinkWrapsItem,
  numberTitle,
}) => {
  const theme = useTheme();
  const { isMobile, isDesktop } = useMobileWatcher(['mobile', 'desktop'], theme.vars);
  const toggles = useSelector(selectToggles);
  const kgRedesign = toggles?.featureKgRedesign;
  const useReactRoutingLinks = toggles?.engs14345ReactRoutingATagsToLinks;

  const absoluteDesktop = !isMobile && (topPercentDesktop !== null || leftPercentDesktop !== null || contentDefaultPositionDesktop === 'INSIDE');
  const absoluteMobile = !!isMobile && (topPercentMobile !== null || leftPercentMobile !== null || contentDefaultPositionMobile === 'INSIDE');

  // Checks determining whether to add padding above/below the article or not.
  const containsText = (title || subtitle || description || callToAction) !== null;
  const textTopDesktop = containsText && topPercentDesktop !== undefined && !absoluteDesktop && contentDefaultPositionDesktop === "ABOVE";
  const textBottomDesktop = containsText && !topPercentDesktop && !absoluteDesktop && contentDefaultPositionDesktop === "BELOW";
  const textTopMob = containsText && topPercentMobile !== undefined && !absoluteMobile && contentDefaultPositionMobile === "ABOVE";
  const textBottomMob = containsText && !topPercentMobile && !absoluteMobile && contentDefaultPositionMobile === "BELOW";

  let positionProps = {};
  if (!isDesktop && animate && animationType === 'BACKGROUNDPARALLAX') {
    positionProps = { absoluteMobile: true, topMobile: null, leftMobile: null };
  }

  const topElement =
    (!!subtitle && 'subtitle') || (!!title && 'title') || (!!description && 'description') || 'cta';

  const componentProps = {
    title,
    subtitle,
    description: cleanHtml(description),
    logo: parseCMSMediaProp(itemLogo),
    callToAction,
    textAlignment,
    colourTheme,
    absoluteDesktop,
    topDesktop: topPercentDesktop,
    leftDesktop: leftPercentDesktop,
    absoluteMobile,
    topMobile: topPercentMobile,
    leftMobile: leftPercentMobile,
    animationType,
    animate,
    animationStyle,
    fontSize,
    callToActionLinkWrapsItem,
    topElement,
    textTopDesktop,
    textBottomDesktop,
    textTopMob,
    textBottomMob,
    numberTitle,
    linkAttr: callToAction?.newWindow ? { target: '_blank', rel: 'noreferrer' } : {},
    ...positionProps,
    kgRedesign,
    useReactRoutingLinks
  };
  return <ModuleContentStructure {...componentProps} />;
};

export default ModuleContent;
