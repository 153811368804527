import React, { FC } from 'react';

import { GlobalAnimationsStyle } from '../../../../sites/animations';
import {
  ContentBlockAnimationMediaBasic,
  ContentBlockMediaBasic,
  Maybe,
} from '../../../types/middleware-types';
import { isImageV2 } from '../../../types/type-checkers';
import formatPromoEvent from '../../../utils/DataLayer/formatPromoEvent';
import Carousel from '../../Carousel';
import ModuleContent from '../MediaModuleContent';
import ModuleItem from '../MediaModuleItem';
import {
  StyledLargeMediaItemDesktopWrapper,
  StyledMediaDesktopWrapper,
  StyledModuleItemsWrapper,
  StyledModuleWrapper,
  StyledScrollMobileWrapper,
  StyledSmallMediaItemDesktopWrapper,
} from './MediaBasicStyles';

export interface MediaBasicStructureProps extends ContentBlockMediaBasic {
  pageName: string | undefined;
  forwardedRef: (node: HTMLDivElement) => void;
  animate: boolean;
  animationType?: Maybe<ContentBlockAnimationMediaBasic>;
  animationStyle: GlobalAnimationsStyle;
  moduleIndex: number;
  hoveredMediaSpacesNumber: Maybe<number>;
  activeIndex: Maybe<number>;
  smallWidth: Maybe<number>;
  isDesktop: boolean;
  setActiveOnHover: (index: number) => void;
  nextImageSizes?: string;
}

const MediaBasicStructure: FC<MediaBasicStructureProps> = ({
  id,
  title,
  subtitle,
  callToAction,
  textAlignment,
  colourTheme,
  fontSize,
  contentDefaultPositionDesktop,
  contentCustomPositionDesktop,
  contentDefaultPositionMobile,
  contentCustomPositionMobile,
  marginDesktop,
  marginMobile,
  items,
  forwardedRef,
  animationType,
  animate,
  animationStyle,
  pageName,
  moduleIndex,
  mobileLayout,
  isDesktop,
  smallWidth,
  hoveredMediaSpacesNumber,
  activeIndex,
  setActiveOnHover,
  backgroundColour,
  nextImageSizes,
}) => {
  const otherItemProps = { parentIsLink: !!callToAction?.url };

  const mobileCarouselItemProps = {
    ...otherItemProps,
    sizes: nextImageSizes
  }

  const desktopContent =
    animationType === 'IMAGEHOVEREXPAND' ? (
      <StyledMediaDesktopWrapper>
        {items.map((item, index) => (
          <StyledLargeMediaItemDesktopWrapper
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            $index={index}
            $activeIndex={activeIndex}
            $smallWidth={smallWidth || 0}
            $hoveredMediaSpacesNumber={hoveredMediaSpacesNumber}
            onMouseEnter={() => setActiveOnHover(index)}
          >
            <StyledSmallMediaItemDesktopWrapper
              $index={index}
              $activeIndex={activeIndex}
              $smallWidth={smallWidth || 0}
              $hoveredMediaSpacesNumber={hoveredMediaSpacesNumber}
            >
              <ModuleItem
                {...item}
                {...otherItemProps}
                fontSizeParent={fontSize}
                sizes={nextImageSizes}
              />
            </StyledSmallMediaItemDesktopWrapper>
          </StyledLargeMediaItemDesktopWrapper>
        ))}
      </StyledMediaDesktopWrapper>
    ) : (
      <StyledModuleItemsWrapper
        $animationType={animationType}
        $animate={animate}
        $animationStyle={animationStyle()}
      >
        {items.map((item, i) => (
          <ModuleItem
            id={id}
            {...item}
            {...otherItemProps}
            // eslint-disable-next-line react/no-array-index-key
            key={`ModuleItem-${id}-${item.title}-${i}`}
            animationType={animationType}
            animate={animate}
            animationStyle={animationStyle}
            moduleIndex={moduleIndex}
            index={i}
            fontSizeParent={fontSize}
            imgPriority={moduleIndex === 0}
            sizes={nextImageSizes}
          />
        ))}
      </StyledModuleItemsWrapper>
    );

  const mobileContent =
    mobileLayout === 'SCROLLBAR' ? (
      <StyledScrollMobileWrapper $itemWidth={items?.length > 1 ? 80 : 100}>
        <Carousel
          items={items}
          component={ModuleItem}
          otherProps={mobileCarouselItemProps}
          scrollableOnMobile={true}
          showArrows={false}
        />
      </StyledScrollMobileWrapper>
    ) : (
      <StyledModuleItemsWrapper
        $animationType={animationType}
        $animate={animate}
        $animationStyle={animationStyle()}
      >
        {items.map((item, i) => (
          <ModuleItem
            id={id}
            {...item}
            {...otherItemProps}
            // eslint-disable-next-line react/no-array-index-key
            key={`ModuleItem-${id}-${item.title}-${i}`}
            animationType={animationType}
            animate={animate}
            animationStyle={animationStyle}
            moduleIndex={moduleIndex}
            index={i}
            fontSizeParent={fontSize}
            sizes={nextImageSizes}
          />
        ))}
      </StyledModuleItemsWrapper>
    );

  return (
    <StyledModuleWrapper
      ref={forwardedRef}
      $marginMobile={marginMobile}
      $marginDesktop={marginDesktop}
      $backgroundColour={backgroundColour}
      $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
      $contentDefaultPositionMobile={contentDefaultPositionMobile}
      data-hookid={`${pageName}ModuleABCDIJ`}
    >
      <div
        role="none"
        onClick={() =>
          formatPromoEvent(
            title,
            1,
            callToAction?.url,
            isImageV2(items[0]?.media) ? items[0]?.media?.desktop.src : '',
            'cta1',
            'click',
          )}
      >
        {isDesktop ? desktopContent : mobileContent}
      </div>
      <ModuleContent
        title={title}
        subtitle={subtitle}
        callToAction={callToAction}
        textAlignment={textAlignment}
        colourTheme={colourTheme}
        contentCustomPositionDesktop={contentCustomPositionDesktop}
        contentDefaultPositionDesktop={contentDefaultPositionDesktop}
        fontSize={fontSize}
        contentCustomPositionMobile={contentCustomPositionMobile}
        contentDefaultPositionMobile={contentDefaultPositionMobile}
      />
    </StyledModuleWrapper>
  );
};

export default MediaBasicStructure;
